import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const withRouter = (Component: typeof React.Component) => {
  function ComponentWithRouterProp(props: never) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();

    return <Component {...props} router={{ location, navigate, params, searchParams }} />;
  }
  return ComponentWithRouterProp;
};

export default withRouter;
