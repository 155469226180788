import '../../styles/components/Login/loginDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { convertMessageToDiv } from '@yojee/helpers/FormValidator/helper';

import * as loginActions from '../../sagas/login/loginActions';
import { CommonService } from '../../services/commonService';
import {
  isIncludeNumber,
  isIncludeSpecialChars,
  isLowerCase,
  isNumber,
  isUpperCase,
  isValidEmail,
  isValidOtp,
  isValidPassword,
} from '../../utils';
import LoginForm from './LoginForm';

class LoginDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      phoneNumber: '',
      otpCode: '',
      loginMode: 'email',
      phoneCodeList: CommonService.getPhoneCodeList(),
      emailLoginForm: {
        email: '',
        password: '',
        validators: {
          email: ['email'],
        },
        errors: {
          email: [],
        },
        touched: {
          email: false,
        },
      },
      getOtpForm: {
        countryCode: '',
        phone: '',
        validators: {
          phone: ['number'],
        },
        errors: {
          phone: [],
        },
        touched: {
          phone: false,
        },
      },
      otpConfirmationForm: {
        countryCode: '',
        phone: '',
        otp: '',
        validators: {
          phone: ['number'],
          otp: ['otp'],
        },
        errors: {
          phone: [],
          otp: [],
        },
        touched: {
          phone: false,
          otp: false,
        },
      },
      signUpForm: {
        email: '',
        password: '',
        name: '',
        countryCode: '',
        phone: '',
        termsAgreed: false,
        validators: {
          email: ['email'],
          password: ['password'],
          phone: ['number'],
        },
        errors: {
          email: [],
          password: [],
          phone: [],
        },
        touched: {
          email: false,
          password: false,
          phone: false,
        },
      },
    };
  }

  renderSignUpForm = () => {
    const { errors, touched, countryCode, phone, email, password, name, termsAgreed } = this.state.signUpForm;
    const { loading } = this.props;
    return (
      <form onSubmit={this.signUp}>
        <div className={'form-group' + (touched.email && errors.email.length > 0 ? ' has-error' : '')}>
          <label htmlFor="sign-up-email">
            <Trans>Email Address</Trans> *
          </label>
          <div className="form-control">
            <input
              type="email"
              className="field"
              id="sign-up-email"
              autoComplete="off"
              onChange={this.onFieldChange('signUpForm', 'email')}
              onBlur={this.onFieldBlur('signUpForm', 'email')}
            />
          </div>
          {touched.email && errors.email.length > 0 ? <p className="error-message">{errors.email}</p> : null}
        </div>
        <div className={'form-group' + (touched.password && errors.password.length > 0 ? ' has-error' : '')}>
          <label htmlFor="sign-up-password">
            <Trans>Password</Trans> *
          </label>
          <div className="form-control">
            <input
              type="password"
              className="field"
              id="sign-up-password"
              autoComplete="new-password"
              onChange={this.onFieldChange('signUpForm', 'password')}
              onBlur={this.onFieldBlur('signUpForm', 'password')}
            />
          </div>
          {touched.password && errors.password.length > 0 && (
            <p className="hint error error-message my-translated-paragraph">{convertMessageToDiv(errors.password)}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="sign-up-name">
            <Trans>Name</Trans> *
          </label>
          <div className="form-control">
            <input
              type="text"
              className="field"
              id="sign-up-name"
              onChange={this.onFieldChange('signUpForm', 'name')}
              onBlur={this.onFieldBlur('signUpForm', 'name')}
            />
          </div>
        </div>
        <div className={'form-group' + (touched.phone && errors.phone.length > 0 ? ' has-error' : '')}>
          <label htmlFor="sign-up-phone">
            <Trans>Phone Number</Trans> *
          </label>
          <div className="form-control fields-group">
            <select
              className="field country-code-select"
              defaultValue={countryCode}
              onChange={this.onFieldChange('signUpForm', 'countryCode')}
            >
              {this.state.phoneCodeList.map((code) => (
                <option key={`sign-up-code-${code}`} value={`+${code}`}>
                  {code}
                </option>
              ))}
            </select>
            <input
              className="field"
              type="number"
              id="sign-up-phone"
              onChange={this.onFieldChange('signUpForm', 'phone')}
              onBlur={this.onFieldBlur('signUpForm', 'phone')}
            />
          </div>
          {touched.phone && errors.phone.length > 0 ? <p className="error-message">{errors.phone}</p> : null}
        </div>

        <div className="terms-agree-container">
          <input
            className="field"
            type="checkbox"
            id="sign-up-terms-agree"
            checked={termsAgreed}
            onChange={this.onFieldChange('signUpForm', 'termsAgreed')}
          />
          <div className="accept-terms-info">
            <label htmlFor="sign-up-terms-agree">
              <p>
                <Trans i18nKey="termsMessage">
                  I confirm I have read, understood and agree to be bound by the following. I also understand how Yojee
                  intends to use my information.
                </Trans>
              </p>
            </label>
            <a href="https://yojee.com/privacy/" rel="noopener noreferrer" target="_blank">
              <Trans>Privacy policy</Trans>
            </a>
            |
            <a
              href={this.props.termsAndConditionsUrl || 'https://yojee.com/customer_tos/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Trans>Customer terms of service</Trans>
            </a>
          </div>
        </div>
        <div className="button-container">
          <button type="button" className="btn btn-default" onClick={this.closeDialog}>
            <Trans>Cancel</Trans>
          </button>
          <button
            type="submit"
            className={'btn btn-primary' + (loading.registerNewUser ? ' loading' : '')}
            disabled={
              !email ||
              errors.email.length ||
              !password ||
              errors.password.length ||
              !phone ||
              errors.phone.length ||
              !name ||
              !termsAgreed ||
              loading.registerNewUser
            }
          >
            <Trans>Sign Up</Trans>
          </button>
        </div>
      </form>
    );
  };

  onFieldChange = (form, field) => (event) => {
    const _target = event.target;
    const value = _target.type === 'checkbox' ? _target.checked : _target.value;
    this.setState(
      {
        [form]: {
          ...this.state[form],
          [field]: value,
        },
      },
      () => this.validateField(form, field, value)
    );
  };

  onFieldBlur = (form, field) => (event) => {
    this.setState({
      [form]: {
        ...this.state[form],
        touched: {
          ...this.state[form].touched,
          [field]: true,
        },
      },
    });
  };

  validateField = (form, field, value) => {
    if (this.state[form].validators[field]) {
      const errors = [];
      this.state[form].validators[field].forEach((validator) => {
        switch (validator) {
          case 'email':
            if (!isValidEmail(value)) {
              errors.push(<Trans key="invalid-email">Invalid Email</Trans>);
            }
            break;
          case 'password':
            if (!value) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="Field is required" />);
            }
            if (!isValidPassword(value)) {
              errors.push(<Trans key={`${field}-error-validator-password-length`} i18nKey="password-length-require" />);
            }

            if (!isLowerCase(value)) {
              errors.push(<Trans key={`${field}-error-validator-lower-case`} i18nKey="invalid-lower-case" />);
            }

            if (!isUpperCase(value)) {
              errors.push(<Trans key={`${field}-error-validator-upper-case`} i18nKey="invalid-upper-case" />);
            }

            if (!isIncludeNumber(value)) {
              errors.push(<Trans key={`${field}-error-validator-include-number`} i18nKey="invalid-include-number" />);
            }

            if (!isIncludeSpecialChars(value)) {
              errors.push(<Trans key={`${field}-error-validator-special-chars`} i18nKey="invalid-special-chars" />);
            }
            break;
          case 'phone':
            if (!isNumber(value)) {
              errors.push(<Trans key="invalid-phone-number">Phone number should contain only digits</Trans>);
            }
            break;
          case 'otp':
            if (!isValidOtp(value)) {
              errors.push(<Trans key="invalid-otp">OTP is invalid</Trans>);
            }
            break;
        }
      });

      this.setState({
        [form]: {
          ...this.state[form],
          errors: {
            ...this.state[form].errors,
            [field]: errors,
          },
        },
      });
    }
  };

  signUp = (event) => {
    event.preventDefault();
    const { email, password, name, countryCode, phone } = this.state.signUpForm;
    this.props.registerNewUser({
      email,
      password,
      name,
      phone: `${countryCode}${phone}`,
      billing_address: '-',
    });
  };

  closeDialog = () => {
    this.props.hideLoginDialog();
  };

  render() {
    return (
      <Dialog
        open={this.props.isShowLoginDialog}
        className="login-dialog-container"
        maxWidth={false}
        scroll="body"
        data-cy="login-signup-form"
      >
        <LoginForm showClose={true} closeDialog={this.closeDialog} showRegisterLink={true}>
          <div className="sign-up-container" id="sign-up">
            <h2>
              <Trans>New User</Trans>
            </h2>
            <p className="info">
              <Trans i18nKey="createAnAccount">Create an account to book.</Trans>
            </p>
            {this.renderSignUpForm()}
            <p className="do-another-action">
              <Trans i18nKey="alreadyHaveAnAccount">Already have an account?</Trans>{' '}
              <a href="#login" className="link">
                <Trans>Log In</Trans>
              </a>
            </p>
          </div>
        </LoginForm>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.login.error,
    loading: state.login.loading,
    isShowLoginDialog: state.login.componentState.isShowLoginDialog,
    termsAndConditionsUrl: state.company.companyInfo.branding.termsAndConditionsUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerNewUser: (payload) => dispatch(loginActions.registerNewUser(payload)),
    hideLoginDialog: () => dispatch(loginActions.hideLoginDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
