import { isDate, isNull, isString } from './validators';

export function getPropertyValue(target, props) {
  let result = null;
  let _prop;

  for (let i = 0; i < props.length; i++) {
    _prop = i === 0 ? target[props[i]] : _prop[props[i]];

    if (isNull(_prop)) {
      break;
    }
  }

  result = _prop;

  return result;
}
// sortType: 'asc' | 'desc'
export function sortArray(target, sortBy, sortType) {
  return target.sort((item1, item2) => {
    const _order = sortType === 'desc' ? -1 : 1;
    const _item1Value = getPropertyValue(item1, sortBy.split('.'));
    const _item2Value = getPropertyValue(item2, sortBy.split('.'));

    if (isNull(_item1Value)) {
      return 1 * _order;
    }
    if (isNull(_item2Value)) {
      return -1 * _order;
    }
    if (isString(_item1Value)) {
      return _item1Value.localeCompare(_item2Value) * _order;
    }
    if (isDate(_item1Value)) {
      return (_item1Value.getTime() - _item2Value.getTime()) * _order;
    }

    return (_item1Value - _item2Value) * _order;
  });
}

export function isObjectEmpty(object) {
  return (
    object === {} ||
    object === null ||
    object === undefined ||
    object.length === 0 ||
    object.toString().trim() === '' ||
    (Object.keys(object).length === 0 && object.constructor === Object)
  );
}

export function flattenArray(array, depth = 1) {
  if (!array || !Array.isArray(array) || array.length < 1) {
    return [];
  }
  return array.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten);
  }, []);
}

export function areObjectsEqual(a, b) {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
}
