import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { SENDER_TYPES } from '@yojee/helpers/constants';

const ACCESSIBLE_SENDER_TYPES = [SENDER_TYPES.organisation, SENDER_TYPES.dispatcher];

function Authorization({ component: Component, ...rest }) {
  const { senderType } = useSelector((state) => state.login.accountInfo);

  // Logged In.
  if (senderType) {
    const isAccessible = ACCESSIBLE_SENDER_TYPES.includes(senderType);

    if (!isAccessible) return <Navigate to="/no-permission" />;
  }

  return <Component {...rest} />;
}

export default Authorization;
