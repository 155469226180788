import '../../styles/components/Common/header.scss';

import { Button } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { SENDER_TYPES } from '@yojee/helpers/constants';

import * as loginActions from '../../sagas/login/loginActions';

function Header(props) {
  const { useBookingPageOnlyForReports, showLoginDialog, signOut } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('/order/batch');
  const [redirectButtonText, setRedirectButtonText] = useState(t('Batch Upload'));

  const anchorEl = useRef(null);

  useEffect(() => {
    const _currentUrl = window.location.href;

    if (_currentUrl.indexOf('/order/batch') > -1) {
      setRedirectUrl('/?tab=booking');
      setRedirectButtonText(t('Manual Order'));
    } else {
      setRedirectUrl('/order/batch');
      setRedirectButtonText(t('Batch Upload'));
    }
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLogin = () => {
    if (useBookingPageOnlyForReports) {
      return navigate('/');
    }
    showLoginDialog();
    setOpen(false);
  };

  const handleLogout = () => {
    signOut();
    if (useBookingPageOnlyForReports) {
      return navigate('/');
    }
    setOpen(false);
  };

  const redirectPage = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
    }
  };

  const onBookDeliveryClicked = () => {
    navigate('/?tab=booking');
  };

  const {
    branding: { logo },
    accountInfo,
  } = props;
  const brandingStyle = props.branding.style;
  const { senderType } = accountInfo;

  const primaryButton = brandingStyle?.['header-primary-button'] ?? null;
  const secondaryButton = brandingStyle?.['header-secondary-button'] ?? null;
  const textColor = brandingStyle?.['header-text-color'] ?? null;
  const openAccountName = open ? 'menu-list-grow' : undefined;

  return (
    <div className="header-container" style={brandingStyle ? brandingStyle['header-background-color'] : null}>
      <div className="header" style={textColor}>
        <Link className="logo" to="/?tab=booking" style={{ backgroundImage: `url(${logo})` }} />

        <div className="dropdown-container">
          {redirectUrl && senderType && senderType !== SENDER_TYPES.individual ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={redirectPage}
              className="redirect-link hidden-sm unset-background-color"
              style={secondaryButton}
              data-cy="redirect-button"
            >
              {redirectButtonText}
            </Button>
          ) : null}
          {!useBookingPageOnlyForReports && (
            <div
              style={primaryButton}
              className="btn btn-primary book-delivery-link unset-background-color"
              onClick={onBookDeliveryClicked}
            >
              <Trans>Book a Delivery</Trans>
            </div>
          )}
          {accountInfo.id ? (
            <Fragment>
              <span
                ref={anchorEl}
                aria-owns={openAccountName}
                aria-haspopup="true"
                className="link"
                style={textColor}
                onClick={handleToggle}
                data-cy="account-name"
              >
                {accountInfo.name}
              </span>
              <Popper open={open} anchorEl={anchorEl?.current} placement="bottom-end" transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList className="menu-container">
                          <MenuItem>
                            <a href="/order/my" target="_blank">
                              <Trans>My Orders</Trans>
                            </a>
                          </MenuItem>
                          <MenuItem onClick={handleLogout} data-cy="logout-item">
                            <Trans>Log Out</Trans>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Fragment>
          ) : (
            <button
              disabled={props.isShowLoginDialog}
              style={primaryButton}
              className="btn btn-primary unset-background-color"
              onClick={handleLogin}
              data-cy="show-login-form-button"
            >
              <Trans>Log In</Trans>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    branding: state.company.companyInfo.branding,
    accountInfo: state.login.accountInfo,
    isShowLoginDialog: state.login.componentState.isShowLoginDialog,
    useBookingPageOnlyForReports: state.company.companyInfo.useBookingPageOnlyForReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(loginActions.signOut()),
    showLoginDialog: () => dispatch(loginActions.showLoginDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
