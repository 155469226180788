import { authService } from '../authService/index';
import { BaseService } from '../baseService/index';

export class OrderApiV4 extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  createOrder = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/orders/create'), {
        ...data,
        options: {
          ...data.options,
          control_flagging_empty_leg: true,
        },
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  senderCreateOrder = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('sender/orders/create'), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  editOrder = (orderNumber, data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}`), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  reapplyOperationsForOrders = (orderIds) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/operations/reset_and_reapply_operations`), { order_ids: orderIds })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  asyncReapplyOperationsForOrders = (orderIds) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/operations/async_reset_and_reapply_operations`), { order_ids: orderIds })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getReapplyOperationsForOrdersStatus = (jobId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/operations/async_reset_and_reapply_operations/${jobId}/status`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkEditOrders = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4('company/orders/bulk_edit'), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getBulkEditOrdersStatus = (jobId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/orders/bulk_edit/${jobId}/status`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getOrderDetail = (orderNumber, orderExternalId) => {
    const requestPayload = {
      number: orderNumber,
      external_id: orderExternalId,
    };

    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/order`), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getBatchUploadSampleUrl({ format, slug, templateId, userType = 'dispatcher' }) {
    return this.getUmbrellaApiUrlV4(
      `public/orders/download_sample?company_slug=${slug}&format=${format}&template_id=${templateId}&user_type=${userType}`
    );
  }

  searchOrders = (payload) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/orders`), payload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

export const orderApiV4 = new OrderApiV4({ authService });
