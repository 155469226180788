import { BookingBaseService } from '@yojee/api/baseService/bookingBaseService';

import { parseBoolean, parseNumber, parseString } from '../utils';
import { authService, clearJwtData, storeJwtData } from './authService';

export class LoginService extends BookingBaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  loginByEmail = ({ email, password, options }) => {
    const payload = { email, password };
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams && urlParams.get('refresh_expiry')) {
      //  backdoor to set refresh_token expiry time in seconds
      //  use it to test case when refresh token expires
      //  by default, refresh_token only expires after 1 week
      payload['refresh_expires_in'] = parseNumber(urlParams.get('refresh_expiry')) || 60 * 60 * 24 * 7;
    }
    return this.authService
      .post(this.getUmbrellaApiUrl('auth/signin'), JSON.stringify(payload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformLoginByEmailData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  registerNewUser = (userData, options) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('auth/signup'), JSON.stringify(userData), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformRegisterNewUserData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  signOut = () => {
    clearJwtData();
  };

  getOtpCode = ({ phoneNumber, options }) => {
    const params = {
      phone: phoneNumber,
    };
    return this.authService
      .get(this.getUmbrellaApiUrl('public/otp'), params, options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformOtpCode(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  loginByPhoneNumber = ({ phoneNumber, otpCode, options }) => {
    const params = {
      phone: phoneNumber,
      otp_code: otpCode,
    };
    return this.authService
      .get(this.getUmbrellaApiUrl('public/verify_otp'), params, options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformLoginByPhoneNumberData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  getSenderInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('sender/info'))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformSenderInfoData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  // Provider in [looker]
  getReportsUrl = (payload, provider = 'looker', options) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`sender/reports_by_user_profile?type=${payload}&provider=${provider}`), options)
      .then((response) => {
        return response['data'] ? response['data']['url'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  sendPasswordResetEmail = (payload, options) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('public/password'), JSON.stringify(payload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updatePassword = (payload, options) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('public/password'), JSON.stringify(payload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  _transformLoginByEmailData = (data) => {
    if (!data) {
      return null;
    }

    //  store jwt data to localStorage
    storeJwtData(data['jwt_tokens']);

    return {
      user_profile_id: data.user_profile_id,
      name: parseString(data['name']),
      phone: parseString(data['phone']),
      email: parseString(data['email']),
      accessToken: parseString(data['jwt_tokens'] && data['jwt_tokens']['access_token']),
      roles: data['roles'],
      dispatcherCompanies: data['dispatcher_companies']
        ? data['dispatcher_companies'].map((company) => ({
            name: parseString(company['title']),
            theme: parseString(company['theme']),
            showServiceType: parseBoolean(company['show_service_type']),
            defaultLanguage: parseString(company['default_lang']),
            slug: parseString(company['company_slug']),
          }))
        : null,
    };
  };

  _transformRegisterNewUserData = (data) => {
    if (!data) {
      return null;
    }

    //  store jwt data to localStorage
    storeJwtData(data['jwt_tokens']);

    return {
      email: parseString(data['email']),
      accessToken: parseString(data['jwt_tokens'] && data['jwt_tokens']['access_token']),
    };
  };

  _transformOtpCode = (data) => {
    if (!data) {
      return null;
    }
    return {};
  };

  _transformLoginByPhoneNumberData = (data) => {
    if (!data) {
      return null;
    }
    return {
      phone: parseString(data['phone']),
      name: parseString(data['name']),
      email: parseString(data['email']),
      id: parseNumber(data['id']),
      accessToken: parseString(data['access_token']),
    };
  };

  _transformSenderInfoData = (data) => {
    if (!data) {
      return null;
    }
    return {
      name: parseString(data['name']),
      id: parseInt(data['id']),
      phone: parseString(data['phone']),
      email: parseString(data['email']),
      senderType: parseString(data['sender_type']),
      billingAddress: parseString(data['billing_address']),
      vehicleTypes: data['vehicle_types']
        ? data['vehicle_types'].map((vehicleType) => {
            return {
              name: parseString(vehicleType['name']),
              id: parseInt(vehicleType['id']),
            };
          })
        : [],
      displaySettings: data['display_settings'] ? this._transformDisplaySettingsData(data['display_settings']) : null,
    };
  };

  _transformDisplaySettingsData = (data) => {
    if (!data) {
      return null;
    }
    return {
      showInvoice: parseBoolean(data['show_invoice']),
    };
  };
}

export const loginService = new LoginService({ authService });
