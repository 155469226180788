import { commonService } from '../services/commonService';

const slug = commonService.getSlugFromUrl();
localStorage.setItem('BOOKING-PAGE-COMPANY_SLUG', slug);
const defaultState = {
  componentState: {
    isShowLoginDialog: false,
    isShowResetPasswordDialog: false,
    otpCodeSent: false,
    resetPasswordEmailSent: false,
    passwordUpdated: false,
    isLoginRequired: false,
    isShowSessionExpiredDialog: false,
  },
  accountInfo: {
    accessToken: null,
    name: null,
    phone: null,
    email: null,
    roles: [],
    id: null,
    senderType: null,
    billingAddress: null,
    vehicleTypes: [],
    displaySettings: null,
    dispatcherCompanies: [],
    slug: slug,
    reportsUrl: null,
    isRequirePayment: false,
  },
  loading: {
    login: false,
    getOtp: false,
    getSenderInfo: false,
    registerNewUser: false,
    reportsUrl: false,
    sendPasswordResetEmail: false,
    updatePassword: false,
  },
  error: {
    failed: false,
    message: null,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  LOGIN_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      accountInfo: { ...state.accountInfo, ...data },
      loading: { ...state.loading, login: false },
    };
  },
  LOGIN_FAILED: (state, sagaData) => {
    const {
      error: { statusCode },
    } = sagaData;

    let msg = '';
    if (statusCode === 429) {
      msg = 'You have exceeded the maximum number of attempts. Please try after some time.';
    }

    return {
      ...state,
      loading: { ...state.loading, login: false },
      error: {
        statusCode,
        failed: true,
        message: msg || 'Log in details are not correct. Please try again.',
      },
    };
  },
  REGISTER_NEW_USER_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      accountInfo: { ...state.accountInfo, ...data },
      loading: { ...state.loading, registerNewUser: false },
    };
  },
  REGISTER_NEW_USER_FAILED: (state, sagaData) => {
    const { error } = sagaData;

    return {
      ...state,
      loading: { ...state.loading, registerNewUser: false },
      error: {
        statusCode: error.statusCode,
        failed: true,
        message: error.message || 'Failed to register new user',
      },
    };
  },
  GET_OTP_CODE_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getOtp: false },
      componentState: { ...state.componentState, otpCodeSent: true },
    };
  },
  GET_OTP_CODE_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getOtp: false },
      componentState: { ...state.componentState, otpCodeSent: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to get OTP code',
      },
    };
  },
  GET_SENDER_INFO_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      accountInfo: { ...state.accountInfo, ...data },
      componentState: { ...defaultState.componentState },
      loading: { ...state.loading, getSenderInfo: false },
    };
  },
  GET_SENDER_INFO_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getSenderInfo: false },
      accountInfo: { ...defaultState.accountInfo },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to get sender info',
      },
    };
  },
  GET_REPORTS_URL_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      accountInfo: { ...state.accountInfo, reportsUrl: sagaData.payload.url },
      loading: { ...state.loading, reportsUrl: false },
    };
  },
  GET_REPORTS_URL_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      accountInfo: { ...state.accountInfo, reportsUrl: null },
      loading: { ...state.loading, reportsUrl: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to load reports',
      },
    };
  },
  SEND_PASSWORD_RESET_EMAIL_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      componentState: { ...state.componentState, resetPasswordEmailSent: true },
      loading: { ...state.loading, sendPasswordResetEmail: false },
    };
  },
  SEND_PASSWORD_RESET_EMAIL_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    if (error.statusCode === 429) {
      error.message = 'You have exceeded the maximum number of attempts. Please try after some time';
    }

    return {
      ...state,
      componentState: { ...state.componentState, resetPasswordEmailSent: false },
      loading: { ...state.loading, sendPasswordResetEmail: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to send email to reset password. Please try again.',
      },
    };
  },
  SET_IS_REQUIRE_PAYMENT: (state, sagaData) => {
    const { isRequirePayment } = sagaData;
    return {
      ...state,
      accountInfo: { ...state.accountInfo, isRequirePayment },
    };
  },
  SHOW_LOGIN_DIALOG: (state, sagaData) => {
    const { isLoginRequired } = sagaData;
    return {
      ...state,
      componentState: { ...state.componentState, isShowLoginDialog: true, isLoginRequired: !!isLoginRequired },
    };
  },
  HIDE_LOGIN_DIALOG: (state, sagaData) => {
    return {
      ...state,
      componentState: { ...state.componentState, isShowLoginDialog: false },
    };
  },
  SHOW_RESET_PASSWORD_DIALOG: (state, sagaData) => {
    return {
      ...state,
      componentState: { ...state.componentState, isShowResetPasswordDialog: true },
    };
  },
  HIDE_RESET_PASSWORD_DIALOG: (state, sagaData) => {
    return {
      ...state,
      componentState: {
        ...state.componentState,
        isShowResetPasswordDialog: false,
        resetPasswordEmailSent: false,
        passwordUpdated: false,
      },
    };
  },
  SHOW_SESSION_EXPIRED_DIALOG: (state) => {
    return {
      ...state,
      componentState: {
        ...state.componentState,
        isShowSessionExpiredDialog: true,
      },
    };
  },
  HIDE_SESSION_EXPIRED_DIALOG: (state) => {
    return {
      ...state,
      componentState: {
        ...state.componentState,
        isShowSessionExpiredDialog: false,
      },
    };
  },
  UPDATE_PASSWORD_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      componentState: { ...state.componentState, passwordUpdated: true },
      loading: { ...state.loading, updatePassword: false },
    };
  },
  UPDATE_PASSWORD_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, updatePassword: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to send email to reset password. Please try again.',
      },
    };
  },
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  GET_RESET_ERRORS: (state) => {
    return {
      ...state,
      error: { ...defaultState.error },
    };
  },
  SIGN_OUT_SUCCESSFUL: () => {
    //  clear user data when user logs out
    return defaultState;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
