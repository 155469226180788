import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class AddressService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getAddresses = (params) => {
    let requestPayload = {
      page: (params && params.page) || 1,
      page_size: (params && params.page_size) || 5,
      sort_by: (params && params.sort_by) || 'external_id',
    };
    if (params.query) {
      requestPayload = { ...requestPayload, query: params.query };
    }
    if (params.sender_id) {
      requestPayload = { ...requestPayload, sender_id: params.sender_id };
    }
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/address_items'), requestPayload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  createAddress = (address) => {
    if (address.sender_id === 0) {
      delete address.sender_id;
    }
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/address_items'), { address_items: [address] })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  uploadAddresses = (file, sender_id) => {
    const formData = new FormData();
    formData.append('file', file);
    if (sender_id && sender_id !== 0) {
      formData.append('sender_id', sender_id);
    }
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/address_items/upload'), formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateAddress = ({ address, updateRelatedOrderSteps }) => {
    if (address.sender_id === 0) {
      delete address.sender_id;
    }
    if (address.location) {
      if (typeof address.location.lat !== 'number') {
        address.location.lat = parseFloat(address.location.lat);
      }
      if (typeof address.location.lng !== 'number') {
        address.location.lng = parseFloat(address.location.lng);
      }
    }
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/address_items/`), {
        address_items: [address],
        ...(updateRelatedOrderSteps ? { update_related_order_steps: true } : {}),
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteAddress = (id) => {
    const options = {};
    options.params = { address_item_ids: [id] };
    return this.authService
      .delete(this.getUmbrellaApiUrl('dispatcher/address_items/'), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteBulkAddress = (ids) => {
    const options = {};
    options.params = { address_item_ids: ids };
    return this.authService
      .delete(this.getUmbrellaApiUrl('dispatcher/address_items/'), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  downloadTemplateSample = ({ format, type }) => {
    return this.getUmbrellaApiUrl(`public/address_items/download_sample?format=${format}&type=${type}`);
  };
}

export const addressService = new AddressService({ authService });
