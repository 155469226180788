import { t } from 'i18next';

export const VESSEL_DATETIME_KEYS = [
  'receiving_start_datetime',
  'receiving_end_datetime',
  'departure_datetime',
  'arrival_datetime',
  'fcl_available_datetime',
  'fcl_storage_datetime',
];

export const getVesselNameKeyMap = () => ({
  receiving_start_datetime: t('Receiving Start Date'),
  receiving_start_time: t('Receiving Start Time'),
  receiving_end_datetime: t('Receiving End Date'),
  receiving_end_time: t('Receiving End Time'),
  departure_datetime: t('Departure Date'),
  departure_time: t('Departure Time'),
  arrival_datetime: t('Arrival Date'),
  arrival_time: t('Arrival Time'),
  fcl_available_datetime: t('Fcl Available Date'),
  fcl_available_time: t('Fcl Available Time'),
  fcl_storage_datetime: t('Fcl Storage Date'),
  fcl_storage_time: t('Fcl Storage Time'),
});
