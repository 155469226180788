import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { RATE_CHARGE_TYPES } from '@yojee/helpers/constants';

import { authService } from './authService/index';
import { BaseService } from './baseService/index';

const rateChargeTypes = [RATE_CHARGE_TYPES.sell, RATE_CHARGE_TYPES.internal_cost, RATE_CHARGE_TYPES.buy];

function promisesAllSettledChargesResultHandler(results) {
  // Handling all statuses of results are 'rejected'.
  const areAllResultsRejected = results.every((result) => result.status === 'rejected');
  if (areAllResultsRejected) {
    const baseService = new BaseService();
    const [sellChargeResult] = results;

    throw baseService.handleNewErrorFormat(sellChargeResult.reason);
  }

  // Handling one of the results status is 'fulfilled'.
  const charges = [];
  const [sellChargeResult, internalCostChargeResult, buyChargeResult] = results;

  const errors = (() => {
    if (sellChargeResult.value) {
      return sellChargeResult.value.errors;
    }

    return [];
  })();

  if (sellChargeResult.status === 'fulfilled') {
    charges.push(...sellChargeResult.value.charges.map((charge) => ({ ...charge, type: RATE_CHARGE_TYPES.sell })));
  }

  if (internalCostChargeResult.status === 'fulfilled') {
    charges.push(
      ...internalCostChargeResult.value.charges.map((charge) => ({
        ...charge,
        type: RATE_CHARGE_TYPES.internal_cost,
      }))
    );
  }

  if (buyChargeResult.status === 'fulfilled') {
    charges.push(...buyChargeResult.value.charges.map((charge) => ({ ...charge, type: RATE_CHARGE_TYPES.buy })));
  }

  return { charges, errors };
}

export class RatingsService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listRatings = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/rates/rate_entries'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  listRateCards = (params) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/rate_entries/search'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  listRateChargeTypes = (requestPayload = { page: 1, page_size: 1000 }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/rates/rate_charge_types'), requestPayload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  publicListRateChargeTypes = () => {
    const requestPayload = { company_slug: getSlugFromUrl() };

    return this.authService
      .get(this.getUmbrellaApiUrl('public/rates/rate_charge_types'), requestPayload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  fetchRating = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/rates/rate_entries/${params.id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  delete = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/rates/rate_entries/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  update = (rateEntry) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/rates/rate_entries/${rateEntry.id}`), rateEntry)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  create = (rateEntry) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/rates/rate_entries`), rateEntry)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  fetchRatingMetadata = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/rates/metadata`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getSenders = (searchName, params = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/senders?sender_type=organisation'), { q: searchName, ...params })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getPartners = (searchName, params = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/partners'), { q: searchName, ...params })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  calculate_order = (number, rate_entry_id) => {
    if (!number || !rate_entry_id) return;
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/rates/calculate_order/${number}`), { rate_entry_id })
      .then((response) => {
        return this.extractData(response);
      });
    // .catch(error => this.handleError(error));
  };

  calculateRateBaseOnOrderData = (orderData) => {
    const promises = rateChargeTypes.map((rateChargeType) =>
      this.calculateRateBaseOnOrderDataAndRateChargeType(orderData, rateChargeType)
    );

    return Promise.allSettled(promises).then(promisesAllSettledChargesResultHandler);
  };

  calculateRateBaseOnOrderDataAndRateChargeType = (orderData, rateChargeType) => {
    const reqPayload = { ...orderData, charge_type: rateChargeType };

    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/orders/calculate/rate`), reqPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  calculateRateForSender = (orderData) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`sender/orders/calculate/rate`), orderData)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  calculateRateForPublic = (orderData) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`public/orders/calculate/rate`), orderData)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  calculateAndSaveRateForSenderBaseOnOrderNumber = (orderNumber) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`sender/order/calculate_save_charges/${orderNumber}`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getBuyRatesByPartners = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('company/partners/rate_entries'), params)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getBuyRateDetails = (id, partnerId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/partners/${partnerId}/rate_entries/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  // Ticket requirement: https://yojeee.atlassian.net/browse/MT-132: Single line charge.
  getCharges = (orderNumber) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}/charges/all`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  calculateAndSaveRates = (orderNumber) => {
    const reqPayload = { data: { types: rateChargeTypes } };

    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}/calculate_and_save_rates`), reqPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkCreateCharges = (orderNumber, reqPayload) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}/charges/batch_create`), reqPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkUpdateCharges = (orderNumber, reqPayload) => {
    return this.authService
      .patch(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}/charges/batch_update`), reqPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkDeleteCharges = (orderNumber, reqPayload) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}/charges/batch_delete`), reqPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

export const ratingsService = new RatingsService({ authService });
